<template>
  <b-container data-aos="fade-up">
    <img
      class="w-100"
      alt="Members of the thaw team stood together"
      src="../../assets/thaw-gallery.jpg"
    />
    <h1 class="mt-5 mb-4 text-primary">Our Gallery</h1>
    <p>
      <i>
        Our volunteer drivers are available to transport you to many different
        local venues. Places we regularly visit include shops, medical clinics
        and hospitals, railway stations, pubs and restaurants and even beauty
        salons! Longer trips can sometimes be undertaken dependent on driver
        availability so just ask!
      </i>
    </p>
    <b-row>
      <b-col md="6" lg="4" class="mb-3" v-for="image in images" :key="image.id">
        <b-card
          :title="image.title"
          class="h-100"
          :sub-title="image.subtitle"
          :img-src="image.src"
          :img-alt="image.alt"
          img-top
          ><template #footer>
            <div class="d-flex flex-row-reverse">
              <b-btn variant="primary" @click="showImage(image)"
                ><font-awesome-icon icon="eye" class="mr-1" />View</b-btn
              >
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="image.show"
      size="xl"
      :title="image.title"
      body-class="p-0"
      hide-footer
      @hidden="resetImage"
    >
      <b-img-lazy class="w-100" :src="image.src" :alt="image.alt" />
    </b-modal>
  </b-container>
</template>

<script>
import { BModal } from "bootstrap-vue";
export default {
  name: "Gallery",
  components: { BModal },
  data: () => ({
    image: {
      show: false,
    },
    images: [
      {
        title: "Better than a Taxi",
        subtitle: "TH&W happy to help you to the destination of your choice.",
        alt: "Picture of Saxilby Railway station",
        src: require("../../assets/gallery/better-than-taxi.jpg"),
      },
      {
        title: "Socialising with Friends",
        subtitle:
          "Want to go for a special meal out? We can take you there and get you home safely.",
        alt: "Picture of a pub in the local area",
        src: require("../../assets/gallery/socialising-with-friends.jpg"),
      },
      {
        title: "Shopping Trips",
        subtitle:
          "Don't want to go into town? We could take you to the Co-op at Saxilby or Collingham",
        alt: "Picture of the Coop in Saxilby",
        src: require("../../assets/gallery/shopping-trips.jpg"),
      },
      {
        title: "Hospital Trips",
        subtitle:
          "Need a trip to the doctors, dentist, local hospital or local beauty salon? We can help!",
        alt: "Picture a sign to the Lincoln County Hospital",
        src: require("../../assets/gallery/appointments.jpg"),
      },
    ],
  }),
  methods: {
    resetImage() {
      this.image = {
        show: false,
      };
    },
    showImage(image) {
      this.image = {
        ...image,
        show: true,
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  height: 170px;
  object-fit: cover;
}
</style>
